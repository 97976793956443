import { FC, ReactNode } from 'react'
import Link, { LinkProps } from 'next/link'
import logger from '../../lib/logger'
import { handleHref, isString } from '../../utils/string'

export interface SafeLinkProps extends LinkProps, Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps> {
  id?: string
  children: ReactNode
  className?: string
}
/**
 * Safe next/link wrapper.
 * Handles empty hrefs without failing.
 * Requires an id-prop to help identifying issues.
 *
 * @param props: SafeLinkProps
 * @returns
 **/
const SafeLink: FC<SafeLinkProps> = ({ id, href, children, className, ...props }) => {
  if (typeof href === 'undefined' || (isString(href) && !href)) {
    logger.warn(`SafeLink: empty href in component with id "${id}"`)
    return <span className={className}>{children}</span>
  }

  const url = isString(href) ? href : href?.pathname || href?.href || ''
  return (
    <Link href={handleHref(url)} {...props} className={className} scroll={false}>
      {children}
    </Link>
  )
}

export default SafeLink
